'use client';
import { useRouter } from 'next/navigation';
import CenteredBox from '../base-ui/CenterBox';
import BottomFixedButton from '../components/MUI/BottomButtonAction';
import PageNotFound from '../components/MUI/PageNotFound';
import { useState } from 'react';
import BackdropCircularIndeterminate from '../components/MUI/BackdropCircularProgress';

export default function NotFound() {
    const router = useRouter();
    const [openCircular, setOpenCircular] = useState(false);
    return (
        <CenteredBox sx={{ height: '100%' }}>
            <PageNotFound />
            <BottomFixedButton
                title="홈으로 이동"
                onClick={() => {
                    router.push('/');
                    setOpenCircular(true);
                }}
                disabled={false}
            />
            {openCircular ? <BackdropCircularIndeterminate open={openCircular} /> : null}
        </CenteredBox>
    );
}
