import CenteredBox from '@/app/base-ui/CenterBox';
import EllipsisTypography from '@/app/base-ui/EllipsisTypography';
import { useHandleBackOrRedirect } from '@/app/hooks/useDetectHistoryRouter';
import { PageNotFoundIcon } from '@/public/icon';
import { Typography, SxProps, Theme, Button } from '@mui/material';

interface PageNotFoundProps {
    title?: string;
    message?: string;
    buttonText?: string;
    sx?: SxProps<Theme>;
    onClick?: () => void;
}

const defaultTitle = `서비스 이용에<br />불편을 드려서 죄송합니다.`;
const defaultMessage = `일시적인 오류로 서비스 이용이 불가합니다.<br />잠시 후 다시 시도해주세요.`;
const PageNotFound = ({ title = defaultTitle, message = defaultMessage, buttonText = '이전 페이지로 이동', sx, onClick }: PageNotFoundProps) => {
    const actionBack = useHandleBackOrRedirect('/');
    const handleOnClick = () => {
        if (onClick) onClick();
        else actionBack();
    };
    return (
        <CenteredBox sx={{ flexDirection: 'column', height: '100%', gap: 7.5, boxShadow: '0px 0px 120px 0px #00000014', ...sx }}>
            <CenteredBox sx={{ gap: 6, flexDirection: 'column' }}>
                <PageNotFoundIcon />
                <CenteredBox sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 2.5 }}>
                    <Typography
                        variant="h2_14Medium"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Typography
                        variant="h2_12Medium"
                        color="error.main"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                </CenteredBox>
            </CenteredBox>
            <CenteredBox>
                <Button
                    sx={{
                        backgroundColor: 'secondary.main',
                        color: 'grayscale.gray07',
                        minWidth: '137.5px',
                        borderRadius: '20px',
                        textTransform: 'unset',
                        minHeight: 36,
                        border: '1px solid var(--text-gray-07000, #000)',
                        '&:hover': {
                            backgroundColor: 'secondary.main',
                            opacity: 0.9,
                        },
                    }}
                    onClick={handleOnClick}
                >
                    <EllipsisTypography
                        lineNumber={1}
                        variant="h2_12Medium"
                        lineHeight="100%"
                    >
                        {buttonText}
                    </EllipsisTypography>
                </Button>
            </CenteredBox>
        </CenteredBox>
    );
};

export default PageNotFound;
