'use client';
import { MAX_SCREEN_WIDTH } from '@/app/constants/layoutConfig';
import { AppBar, Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import deepmerge from 'deepmerge';
import { ArrowToolTipIcon } from '@/public/icon';

interface BottomFixedButtonProps {
    onClick?: () => void;
    title: React.ReactNode;
    type?: 'primary' | 'secondary';
    disabled?: boolean;
    isLoading?: boolean;
    hasToolTip?: boolean;
    TooltipContent?: ReactNode;
    sx?: SxProps;
    acceptClickWhenDisable?: boolean;
}

const BottomFixedButton: React.FC<BottomFixedButtonProps> = ({ onClick, title, disabled = false, isLoading = false, hasToolTip = false, acceptClickWhenDisable = false, TooltipContent, sx = {} }) => {
    const theme = useTheme();
    const ButtonProps = {
        disabled: (
            <LoadingButton
                sx={getButtonDisabledStyles(theme)}
                onClick={acceptClickWhenDisable ? () => onClick?.() : () => {}}
                disabled={acceptClickWhenDisable ? false : true}
            >
                <Typography variant="h2_16Bold">{title}</Typography>
            </LoadingButton>
        ),
        active: (
            <LoadingButton
                sx={getButtonActiveStyles(theme)}
                onClick={onClick}
                loadingPosition="center"
                loading={isLoading}
            >
                {isLoading ? null : <Typography variant="h2_16Bold">{title}</Typography>}
            </LoadingButton>
        ),
    };

    return (
        <AppBar
            component="footer"
            sx={deepmerge<SxProps<Theme>>(
                {
                    ...styles.Paper,
                    top: 'auto',
                    position: 'fixed',
                    bottom: 0,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
                sx
            )}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}>
                {hasToolTip && (
                    <Box
                        sx={{
                            zIndex: 999,
                            width: '100%',
                            position: 'relative',
                            color: (theme) => theme.palette.grayscale.gray07,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {TooltipContent}
                        <Box sx={{ position: 'absolute', bottom: -13, left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
                            <ArrowToolTipIcon />
                        </Box>
                    </Box>
                )}
                {disabled ? ButtonProps['disabled'] : ButtonProps['active']}
            </Box>
        </AppBar>
    );
};

const styles = {
    Paper: {
        maxWidth: MAX_SCREEN_WIDTH,
        margin: '0 auto',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
    },
};

const getButtonActiveStyles = (theme: Theme) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    maxWidth: MAX_SCREEN_WIDTH,
    lineHeight: 1.5,
    borderRadius: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
    },
    height: 60,
    padding: '15px 20px',
});

const getButtonDisabledStyles = (theme: Theme) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 0,
    width: '100%',
    maxWidth: MAX_SCREEN_WIDTH,
    lineHeight: 1.5,
    border: 'none',
    backgroundColor: theme.palette.grayscale.gray04,
    color: theme.palette.background.default,
    '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.grayscale.gray04,
    },
    '&:not(:active)': {
        color: theme.palette.background.default,
    },
    // '&:active': {
    //     boxShadow: 'none',
    // },
    height: 60,
});

export default BottomFixedButton;
