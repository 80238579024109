'use client';
import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { TypographyProps } from '@mui/material';

interface EllipsisTypographyProps extends TypographyProps {
    children: ReactNode;
    maxWidth?: any;
    lineNumber?: number | string;
    stringBehind?: string;
    iconBehind?: ReactNode;
}

const EllipsisTypography: React.FC<EllipsisTypographyProps> = ({
    children,
    iconBehind,
    variant = 'h2_14Bold',
    color = 'grayscale.gray07',
    maxWidth = '90%',
    lineNumber = 2,
    stringBehind,
    sx = {},
    ...props
}) => {
    return (
        <>
            <Typography
                variant={variant}
                color={color}
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: lineNumber,
                    WebkitBoxOrient: 'vertical',
                    maxWidth: maxWidth,
                    ...sx,
                }}
                {...props}
            >
                {children}
                {iconBehind && <span style={{ marginLeft: 2, position: 'absolute', bottom: '-2px' }}>{iconBehind}</span>}
            </Typography>
            {stringBehind ?? null}
        </>
    );
};

export default EllipsisTypography;
